import "./App.scss";
import "@mantine/carousel/styles.css";
import "@mantine/core/styles.css";
import {
  AppShell,
  Flex,
  Group,
  Image,
  Button,
  Stack,
  Text,
  List,
  ThemeIcon,
  Anchor,
  Grid,
  Center,
} from "@mantine/core";

function App() {
  return (
    <AppShell header={{ height: 64 }} padding="xl">
      <AppShell.Header>
        <Flex
          justify="space-between"
          align="center"
          wrap="wrap"
          h={64}
          ml="xl"
          mr="xl"
        >
          <Group>
            <Image src="assets/img/logo.svg" h={40} />
          </Group>
          <Group>
            <Button variant="transparent" color="black">
              Explore
            </Button>
            <Button variant="primary" radius="xl" color="rell-green">
              Log In
            </Button>
          </Group>
        </Flex>
      </AppShell.Header>
      <AppShell.Main p={0} m={0} pt={64} style={{ overflow: "hidden" }}>
        <Grid
          gutter="xl"
          justify="center"
          align="center"
          className="section-1-min-height"
          style={{
            alignContent: "center",
            backgroundColor: "#1C4434",
            backgroundImage: "url(assets/img/mycellium_network.svg)",
            backgroundPosition: "center",
          }}
        >
          <Grid.Col
            span={{ base: 8, md: 5 }}
            offset={{ base: 1, md: 1 }}
            order={{ base: 2, md: 1 }}
          >
            <Center className="top-bottom-padding">
              <Stack>
                <Text className="hero-primer" c="rell-green">
                  Predict, Pick, Enjoy
                </Text>
                <Text className="hero-title" c="#fff0e6">
                  Know when & where to forage for mushrooms
                </Text>
                <Text
                  className="hero-subtitle"
                  c="#fff"
                  style={{ zIndex: "2" }}
                >
                  Using advanced weather data and machine learning insights,
                  Rell guides you to the best spots for Morels, Chanterelles,
                  and more.
                </Text>
                <Button
                  variant="primary"
                  radius="xl"
                  color="rell-green"
                  w={163}
                  h={52}
                  mt={24}
                  style={{ zIndex: "2", fontSize: "16px" }}
                >
                  Start Exploring
                </Button>
                <hr
                  style={{
                    width: "100%",
                    border: "1px solid rgba(255, 255, 255, 0.5)",
                  }}
                />
                <Flex align="center" wrap="wrap" gap={8}>
                  <Text
                    className="hero-subtitle"
                    c="#fff"
                    w="150px"
                    style={{
                      fontStyle: "italic",
                    }}
                  >
                    Trusted & tested by members of
                  </Text>
                  <Image
                    src="assets/img/nyms-logo.svg"
                    h={88}
                    w={275}
                    style={{
                      filter:
                        "invert(0%) sepia(2%) saturate(4%) hue-rotate(319deg) brightness(98%) contrast(100%)",
                    }}
                    fallbackSrc="https://placehold.co/275x88?text=NYMSLogo"
                  ></Image>
                </Flex>
              </Stack>
            </Center>
          </Grid.Col>
          <Grid.Col
            span={{ base: 8, md: 5 }}
            offset={{ base: 1, md: 1 }}
            order={{ base: 1, md: 2 }}
          >
            <Center className="top-bottom-padding hero-image">
              <Image
                radius="md"
                src="assets/img/mushroom-man.png"
                w="auto"
                fit="contain"
                fallbackSrc="https://placehold.co/425x460?text=Placeholder"
                style={{
                  maxWidth: "100%",
                }}
              ></Image>
            </Center>
          </Grid.Col>
        </Grid>
        <Grid
          gutter="xl"
          justify="center"
          align="center"
          className="section-2-min-height"
          style={{
            backgroundColor: "#C6F7DF",
          }}
        >
          <Grid.Col
            span={{ base: 8, md: 5 }}
            offset={{ base: 1, md: 1 }}
            order={{ base: 1, md: 1 }}
          >
            <Center className="top-bottom-padding">
              <Stack gap="md">
                <Text className="hero-primer" c="rell-green">
                  How it works
                </Text>
                <Text className="hero-title" c="#122E23">
                  Rell makes it easy to plan your next foraging adventure
                </Text>
                <Button
                  variant="primary"
                  radius="xl"
                  color="rell-green"
                  w={160}
                  h={52}
                  mt={25}
                >
                  Start exploring
                </Button>
              </Stack>
            </Center>
          </Grid.Col>
          <Grid.Col
            span={{ base: 8, md: 5 }}
            offset={{ base: 1, md: 1 }}
            order={{ base: 2, md: 2 }}
          >
            <Center className="top-bottom-padding">
              <Stack gap="md">
                <List spacing="xl" size="lg" center>
                  <List.Item
                    styles={{
                      itemWrapper: {
                        display: "flex",
                        alignItems: "flex-start",
                      },
                    }}
                    icon={
                      <ThemeIcon color="black" size={32} radius="xl">
                        <Text fw={700} style={{ fontSize: "20px" }}>
                          1
                        </Text>
                      </ThemeIcon>
                    }
                  >
                    <Text
                      fw={700}
                      style={{ fontSize: "24px", lineHeight: "29.05px" }}
                    >
                      Pick a mushroom
                    </Text>
                    <Text style={{ fontSize: "20px", lineHeight: "24.2px" }}>
                      Select from a variety of species
                    </Text>
                  </List.Item>
                  <List.Item
                    styles={{
                      itemWrapper: {
                        display: "flex",
                        alignItems: "flex-start",
                      },
                    }}
                    icon={
                      <ThemeIcon color="black" size={32} radius="xl">
                        <Text fw={700} style={{ fontSize: "20px" }}>
                          2
                        </Text>
                      </ThemeIcon>
                    }
                  >
                    <Text
                      fw={700}
                      style={{ fontSize: "24px", lineHeight: "29.05px" }}
                    >
                      Pick a spot
                    </Text>
                    <Text style={{ fontSize: "20px", lineHeight: "24.2px" }}>
                      Find the best locations near you
                    </Text>
                  </List.Item>
                  <List.Item
                    styles={{
                      itemWrapper: {
                        display: "flex",
                        alignItems: "flex-start",
                      },
                    }}
                    icon={
                      <ThemeIcon color="black" size={32} radius="xl">
                        <Text fw={700} style={{ fontSize: "20px" }}>
                          3
                        </Text>
                      </ThemeIcon>
                    }
                  >
                    <Text
                      fw={700}
                      style={{ fontSize: "24px", lineHeight: "29.05px" }}
                    >
                      View growth conditions
                    </Text>
                    <Text style={{ fontSize: "20px", lineHeight: "24.2px" }}>
                      For the past, present, and future
                    </Text>
                  </List.Item>
                  <List.Item
                    styles={{
                      itemWrapper: {
                        display: "flex",
                        alignItems: "flex-start",
                      },
                    }}
                    icon={
                      <ThemeIcon color="black" size={32} radius="xl">
                        <Text fw={700} style={{ fontSize: "20px" }}>
                          4
                        </Text>
                      </ThemeIcon>
                    }
                  >
                    <Text
                      fw={700}
                      style={{ fontSize: "24px", lineHeight: "29.05px" }}
                    >
                      Forage
                    </Text>
                    <Text style={{ fontSize: "20px", lineHeight: "24.2px" }}>
                      Enjoy a successful foraging experience
                    </Text>
                  </List.Item>
                </List>
              </Stack>
            </Center>
          </Grid.Col>
        </Grid>
        <Center className="product-screenshot-image">
          <Image
            src="assets/img/product_screenshot.png"
            fallbackSrc="https://placehold.co/531x870?text=Placeholder"
          ></Image>
        </Center>
        <Grid
          justify="center"
          align="center"
          className="section-3-min-height"
          style={{
            alignContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <Grid.Col
            span={{ base: 8, md: 5 }}
            offset={{ base: 1, md: 1 }}
            order={{ base: 2, md: 1 }}
          >
            <Center className="top-bottom-padding">
              <Stack gap="md">
                <Text className="hero-primer" c="rell-green">
                  Precision foraging
                </Text>
                <Text className="hero-title" c="#122E23">
                  Better accuracy with our mushroom growth conditions
                </Text>
                <Text className="hero-subtitle" c="#3F3F3F">
                  Using a combination of topographic, weather, and seasonality
                  data, Rell predicts the best areas and most ideal times to go
                  foraging.
                </Text>
                <Button
                  variant="primary"
                  radius="xl"
                  color="rell-green"
                  w={163}
                  h={52}
                  mt={24}
                  style={{ zIndex: "2" }}
                >
                  Start Exploring
                </Button>
              </Stack>
            </Center>
          </Grid.Col>
          <Grid.Col
            span={{ base: 8, md: 5 }}
            offset={{ base: 1, md: 1 }}
            order={{ base: 1, md: 2 }}
          >
            <Center className="top-bottom-padding hero-image">
              <Image
                radius="md"
                src="assets/img/marketing-accuracy.png"
                w="auto"
                fit="contain"
                fallbackSrc="https://placehold.co/357x444?text=Placeholder"
                style={{ maxWidth: "100%" }}
              ></Image>
            </Center>
          </Grid.Col>
        </Grid>
        <Grid
          gutter="xl"
          justify="center"
          align="center"
          className="section-4-min-height"
          style={{
            alignContent: "center",
            backgroundColor: "#1C4434",
            backgroundImage: "url(assets/img/mycellium_network.svg)",
            backgroundPosition: "center",
          }}
        >
          <Grid.Col>
            <Center className="top-bottom-padding">
              <Stack gap="md" align="center">
                <Text className="hero-primer" c="rell-green">
                  Mushroom Species
                </Text>
                <Text className="hero-title" c="#fff" w="55vw" ta="center">
                  Forage for the mushrooms you know and love
                </Text>
                <Flex wrap="wrap" pt={32} pb={32} justify="center">
                  <Image
                    src="assets/img/species-1.png"
                    h={145}
                    w={316}
                    fallbackSrc="https://placehold.co/316x145?text=Placeholder"
                  ></Image>
                  <Image
                    src="assets/img/species-2.png"
                    h={145}
                    w={316}
                    fallbackSrc="https://placehold.co/316x145?text=Placeholder"
                  ></Image>
                  <Image
                    src="assets/img/species-3.png"
                    h={145}
                    w={316}
                    fallbackSrc="https://placehold.co/316x145?text=Placeholder"
                  ></Image>
                  <Image
                    src="assets/img/species-more.png"
                    h={145}
                    w={316}
                    fallbackSrc="https://placehold.co/316x145?text=Placeholder"
                  ></Image>
                </Flex>
                <Button
                  variant="primary"
                  radius="xl"
                  color="rell-green"
                  w={163}
                  h={52}
                  mt={24}
                  style={{ zIndex: "2" }}
                >
                  Start Exploring
                </Button>
              </Stack>
            </Center>
          </Grid.Col>
        </Grid>
        <Grid
          gutter="xl"
          justify="center"
          align="center"
          className="section-5-min-height"
          style={{
            alignContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <Grid.Col
            span={{ base: 8, md: 5 }}
            offset={{ base: 1, md: 1 }}
            order={{ base: 2, md: 1 }}
          >
            <Center className="top-bottom-padding">
              <Stack gap="md">
                <Text className="hero-primer" c="rell-green">
                  Mobile friendly
                </Text>
                <Text className="hero-title" c="#122E23">
                  Take us with you on your next forage
                </Text>
                <Text className="hero-subtitle" c="#3F3F3F">
                  Rell also works great on your phone so you can take it with
                  you on your foraging advetures
                </Text>
                <Button
                  variant="primary"
                  radius="xl"
                  color="rell-green"
                  w={163}
                  h={52}
                  mt={24}
                  style={{ zIndex: "2" }}
                >
                  Start Exploring
                </Button>
              </Stack>
            </Center>
          </Grid.Col>
          <Grid.Col
            span={{ base: 8, md: 5 }}
            offset={{ base: 1, md: 1 }}
            order={{ base: 1, md: 2 }}
          >
            <Center className="top-bottom-padding hero-image product-mobile-image">
              <Image
                radius="md"
                src="assets/img/product_screenshot_mobile.png"
                w="auto"
                fit="contain"
                fallbackSrc="https://placehold.co/388x597?text=Placeholder"
              ></Image>
            </Center>
          </Grid.Col>
        </Grid>
        <div
          className="section-6-min-height"
          style={{
            backgroundColor: "#fafafa",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Stack
            gap="md"
            justify="center"
            align="center"
            className="have-questions"
            style={{
              backgroundColor: "#BFF9DD",
              backgroundImage: "url(assets/img/topo_bg.svg)",
              backgroundPosition: "center",
            }}
          >
            <Text className="hero-title">Have questions?</Text>
            <Text className="hero-primer">We're here to help!</Text>
            <Anchor
              className="hero-title"
              href="mailto:info@rell.app"
              target="_blank"
              underline="never"
              c="rell-green.8"
            >
              info@rell.app
            </Anchor>
          </Stack>
        </div>
        <Grid
          justify="center"
          align="stretch"
          className="footer-section-min-height"
          style={{
            alignContent: "center",
            borderTop: "1px solid #CECECE",
          }}
        >
          <Grid.Col
            span={{ sm: "auto", xs: 12 }}
            order={{ base: 2, sm: 1 }}
            style={{ alignContent: "center" }}
          >
            <div className="rell-copyright">© Copyright Rell, 2024</div>
          </Grid.Col>
          <Grid.Col
            span={{ sm: "auto", xs: 12 }}
            offset={{ sm: 4 }}
            order={{ base: 1, sm: 2 }}
            style={{ alignContent: "center" }}
            className="rell-footer"
          >
            <Button variant="transparent" color="#1C8245">
              Privacy Policy
            </Button>
            <Button variant="transparent" color="#1C8245">
              Terms
            </Button>
            <Button variant="transparent" color="#1C8245">
              Cookie Policy
            </Button>
          </Grid.Col>
        </Grid>
      </AppShell.Main>
    </AppShell>
  );
}

export default App;
