import React from "react";
import ReactDOM from "react-dom/client";
import { createTheme, MantineProvider } from "@mantine/core";
import App from "./App.tsx";

const theme = createTheme({
  fontFamily: "Inter, sans-serif",
  fontFamilyMonospace: "Monaco, Courier, monospace",
  primaryColor: "rell-green",
  primaryShade: 8,
  colors: {
    "rell-green": [
      "#e9fcf1",
      "#d8f6e4",
      "#b1ecc8",
      "#85e2aa",
      "#62d990",
      "#4cd480",
      "#3ed176",
      "#2fba64",
      "#25a558",
      "#0f8f49",
    ],
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <App />
    </MantineProvider>
  </React.StrictMode>
);
